module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LNER Share a Journey","short_name":"LNER","start_url":"/","background_color":"#F5F5F5","theme_color":"#CE0E2D","display":"minimal-ui","icon":"src/images/lner-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7da4dd97068f6a5485492dbdc25110af"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
